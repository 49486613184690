import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
import textView from '../../../../../../modules/text_view';
import selectBudgetFee from './selectBudgetFee';

formCreate.component('textView', textView);
formCreate.component('selectBudgetFee', selectBudgetFee);

export default {
  name: 'adjust-edit',
  extends: Form,
  props: {
    functionCode: String,
    modalType: String,
    rowId: String,
    feeBudgetCode: String,
    // fieldChange: Function,
  },
  data() {
    return {
      formFunctionCode: this.functionCode,
      buttons: {
        submit: false,
        close: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  watch: {
    rowId() {
      this.getDetails();
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'feeBudgetCode' && this.modalType === 'right') {
        v.type = 'selectBudgetFee';
        v.props = {
          code: this.feeBudgetCode,
        };
      } else if (v.field === 'changeAmount' || v.field === 'businessRemarks') {
        v.props = {
          ...v.props,
          disabled: true,
        };
      } else if (v.field === 'attachmentList') {
        v.props = {
          ...v.props,
          disabled: true,
          fileMsg: '请上传pdf,docx,xlsx,ppt,png,jpg等格式,仅能上传一个',
          limit: 1,
        };
      }
      return v;
    },
    // 两个浮点数相减
    accSub(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // eslint-disable-next-line no-restricted-properties
      const n = (r1 >= r2) ? r1 : r2;
      return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(this.modalType !== 'left', ['changeAmount', 'businessRemarks']);

      const changeAmount = this.getRule('changeAmount');
      const canUseAmount = this.getRule('canUseAmount');
      const feeBudgetCode = this.getRule('feeBudgetCode');

      changeAmount.on.change = (e) => {
        // const num = e ? Number(canUseAmount.value) - Number(e) : Number(canUseAmount.value);
        const num = e ? this.accSub(Number(canUseAmount.value), Number(e)) : Number(canUseAmount.value);
        this.setValue({ afterAdjustAmount: num });
        this.$emit('fieldChange', e);
      };
      feeBudgetCode.on.change = (e) => {
        this.$emit('fieldChange', e, 'replace');
      };

      if (this.rowId) {
        this.getDetails();
      }
    },
    getDetails() {
      console.log(this.rowId);
      request
        .get('/tpm/tpmFeeBudgetController/query', {
          id: this.rowId,
        })
        .then((res) => {
          if (res.success) {
            const data = res.result;
            data.afterAdjustAmount = data.canUseAmount;
            this.setValue(data);
          }
        });
    },
  },
};
