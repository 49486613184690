import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import BudgetProductList from './product-list.vue';
import BudgetCustomerList from './customer-list.vue';
import BudgetPositionList from './position-list.vue';
import Budget from './budget.vue';
import BudgetOrgList from './org-list.vue';
import BudgetCompanyList from './company_list.vue';

formCreate.component('BudgetProductList', BudgetProductList);
formCreate.component('BudgetCustomerList', BudgetCustomerList);
formCreate.component('BudgetPositionList', BudgetPositionList);
formCreate.component('BudgetOrgList', BudgetOrgList);
formCreate.component('Budget', Budget);
formCreate.component('BudgetCompanyList', BudgetCompanyList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tmp_budget_fee_form',
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productLevelName') { // 选择产品系列
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select-product-level',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'budgetSubjectsName') { // 选择预算科目
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select-budget',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }

      if (v.field === 'customerName') { // 选择客户
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select-customer',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext10') { // 选择职位
        v.props = {
          ...v.props,
          params: {
            functionCode: 'position_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'orgName') { // 选择组织
        v.props = {
          ...v.props,
          params: {
            functionCode: 'select-org',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'ext8') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'enableStatus') { // 启用状态
        v.value = '009';
      }
      if (v.field === 'ext1') { // 财年
        v.restful = '/tpm/tpmFiscalYearSettingController/list';
        v.restfulParams = { pageSize: -1, enableStatus: '009' };
        v.props = {
          ...v.props,
          remoteParams: 'yearName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'yearName',
          value: 'year',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(true, ['productLevelName']);
      const feeBudgetType = this.getRule('feeBudgetType');
      const customerName = this.getRule('customerName');
      const orgName = this.getRule('orgName');
      const productLevelName = this.getRule('productLevelName');
      const ext10 = this.getRule('ext10');
      const budgetSubjectsName = this.getRule('budgetSubjectsName');
      const ext8 = this.getRule('ext8');
      const ext12 = this.getRule('ext12');
      if (this.formConfig.code === 'add') { // 默认为否
        this.setValue({
          ext12: 'N',
        });
      }
      this.disabled(true, 'budgetSubjectsName');
      feeBudgetType.on.change = (e) => {
        if (e) {
          this.setValue({
            ext1: null,
            ext7: null,
            ext8: null,
            budgetSubjectsCode: null,
            budgetSubjectsName: null,
            orgCode: null,
            orgName: null,
            customerCode: null,
            customerName: null,
            productLevelCode: null,
            productLevelName: null,
            ext10: null,
            ext9: null,
            initAmount: null,
            enableStatus: '009',
            remarks: null,
          });
          if (e === 'running') { // 市场运营费，显示产品，其它的隐藏
            this.hiddenFields(false, ['productLevelName']);
          } else {
            this.hiddenFields(true, ['productLevelName']);
            this.setValue({
              productLevelName: null,
              productLevelCode: null,
            });
          }
          budgetSubjectsName.props = {
            ...budgetSubjectsName.props,
            params: {
              ...budgetSubjectsName.props.params,
              paramData: { subjectsTypeCode: feeBudgetType.value, enableStatus: '009' },
            },
          };
          this.disabled(false, 'budgetSubjectsName');
        } else {
          this.hiddenFields(true, ['productLevelName']);
          this.setValue({
            budgetSubjectsName: null,
            budgetSubjectsCode: null,
            productLevelName: null,
            productLevelCode: null,
          });
          this.disabled(true, 'budgetSubjectsName');
        }
      };
      // 公司主体
      ext8.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            ext8: e[0].companyName,
            ext7: e[0].companyCode,
          });
        } else {
          this.setValue({
            ext8: null,
            ext7: null,
          });
        }
      };

      // 提示：客户和组织互斥，二者只能选其一
      customerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.hiddenFields(true, ['orgName', 'ext10']);
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
            orgCode: null,
            orgName: null,
            ext10: null,
            ext9: null,
          });
        } else {
          this.hiddenFields(false, ['orgName', 'ext10']);
          this.hiddenFields(false, ['customerName']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: null,
            orgName: null,
            ext10: null,
            ext9: null,
          });
        }
      };

      // 组织
      orgName.on.change = (e) => {
        if (e && e.length > 0) {
          this.hiddenFields(true, ['customerName']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: e[0].orgCode,
            orgName: e[0].orgName,
          });
        } else {
          this.hiddenFields(false, ['customerName']);
          this.hiddenFields(false, ['orgName', 'ext10']);
          this.setValue({
            customerName: null,
            customerCode: null,
            orgCode: null,
            orgName: null,
            ext10: null,
            ext9: null,
          });
        }
      };

      // 职位
      ext10.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            ext10: e[0].positionName,
            ext9: e[0].positionCode,
          });
        } else {
          this.setValue({
            ext10: null,
            ext9: null,
          });
        }
      };

      // 产品系列
      productLevelName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productLevelName: e[0].productLevelName,
            productLevelCode: e[0].productLevelCode,
          });
        } else {
          this.setValue({
            productLevelName: null,
            productLevelCode: null,
          });
        }
      };

      // 预算科目
      budgetSubjectsName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            budgetSubjectsName: e[0].budgetSubjectsName,
            budgetSubjectsCode: e[0].budgetSubjectsCode,
          });
        } else {
          this.setValue({
            budgetSubjectsName: null,
            budgetSubjectsCode: null,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmFeeBudgetController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              this.setValue(res.result);
              if (!res.result.ext12) {
                this.setValue({
                  ext12: 'N',
                });
              }
              budgetSubjectsName.props = {
                ...budgetSubjectsName.props,
                params: {
                  ...budgetSubjectsName.props.params,
                  paramData: { subjectsTypeCode: feeBudgetType.value },
                },
              };
              if (res.result.feeBudgetType === 'running') { // 市场运营费，显示产品，其它的隐藏
                this.hiddenFields(false, ['productLevelName']);
              } else {
                this.hiddenFields(true, ['productLevelName']);
                this.setValue({
                  productLevelName: null,
                  productLevelCode: null,
                });
              }
              this.disabled(false, 'budgetSubjectsName');
              if (orgName.value) { // 组织存在，隐藏客户
                this.hiddenFields(true, ['customerName']);
              }
              if (customerName.value) { // 客户存在，隐藏组织和职位
                this.hiddenFields(true, ['orgName', 'ext10']);
              }
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmFeeBudgetExt/save';
        const params = {
          ...formData, budgetMonth: '1', budgetYear: '2021', budgetQuater: '1', ext19: 'Y',
        };

        if (this.formConfig.code === 'edit') {
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
