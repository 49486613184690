import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import textView from '../../../../../../modules/text_view';

formCreate.component('textView', textView);
export default {
  name: 'form-change',
  extends: Form,
  props: {
    rules: Array,
  },
  data() {
    return {
      formFunctionCode: 'tpm_budget_fee_change',
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  watch: {},
  mounted() {},
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      v.props = {
        ...v.props,
        disabled: true,
      };
      if (v.field === 'afterAdjustAmount') {
        v.props = {
          ...v.props,
          disabled: true,
        };
      } else if (v.field === 'changeAmount') {
        v.props = {
          ...v.props,
          controls: false,
          min: 0,
          disabled: true,
        };
      }
      if (v.field === 'businessRemarks' || v.field === 'feeBudgetDetailType') {
        v.props = {
          ...v.props,
          disabled: false,
        };
      }
      if (v.field === 'attachmentList') {
        v.props = {
          ...v.props,
          disabled: false,
          fileMsg: '请上传pdf,docx,xlsx,ppt,png,jpg等格式,仅能上传一个',
          limit: 1,
        };
      }
      return v;
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 两个浮点数相减
    accSub(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // eslint-disable-next-line no-restricted-properties
      const n = (r1 >= r2) ? r1 : r2;
      return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
    },
    // 表单渲染完成后回调
    formComplete() {
      const feeBudgetDetailType = this.getRule('feeBudgetDetailType');
      const canUseAmount = this.getRule('canUseAmount');
      const changeAmount = this.getRule('changeAmount');

      feeBudgetDetailType.on.change = (e) => {
        const allVal = canUseAmount.value;
        const val = changeAmount.value;
        this.disabled(!e, 'changeAmount');

        if (e === 'additional' && val && Number(val) !== 0) {
          // this.setValue({ afterAdjustAmount: Number(allVal) + Number(val) });
          this.setValue({ afterAdjustAmount: this.accAdd(Number(allVal), Number(val)) });
        } else if (e === 'cut_out' && val && Number(val) !== 0) {
          // this.setValue({ afterAdjustAmount: Number(allVal) - Number(val) });
          this.setValue({ afterAdjustAmount: this.accSub(Number(allVal), Number(val)) });
        } else {
          this.setValue({ changeAmount: '', afterAdjustAmount: allVal });
        }
      };

      changeAmount.on.change = (e) => {
        const type = feeBudgetDetailType.value;
        const val = canUseAmount.value;

        if (type === 'additional') {
          // this.setValue({ afterAdjustAmount: Number(val) + Number(e || 0) });
          this.setValue({ afterAdjustAmount: this.accAdd(Number(val), Number(e || 0)) });
        } else {
          // this.setValue({ afterAdjustAmount: Number(val) - Number(e || 0) });
          this.setValue({ afterAdjustAmount: this.accSub(Number(val), Number(e || 0)) });
        }
      };

      request
        .get('/tpm/tpmFeeBudgetController/query', {
          id: this.formConfig.row.id,
        })
        .then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmFeeBudgetController/change';
        const params = { ...formData };
        params.id = this.formConfig.row.id;

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
