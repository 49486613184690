import request from '../../../../../../utils/request';
import Form from './edit-adjust';
import SelectBudgetFee from './selectBudgetFee';

export default {
  name: 'form-edit-adjust',
  props: {
    formConfig: Object,
  },
  components: {
    LeftForm: Form,
    RightForm: Form,
  },
  data() {
    return {
      rightSelect: {},
    };
  },
  created() {},
  methods: {
    handleInitRightChange(row, type) {
      this.rightSelect = row;
      console.log(this.rowId);

      if (type === 'init') {
        this.$refs.leftForm.disabled(false, ['changeAmount', 'businessRemarks', 'attachmentList']);
      } else {
        this.$refs.leftForm.setValue({ changeAmount: 0, businessRemarks: '', attachmentList: null });
      }
    },
    handleFieldLeftChange(value) {
      const canUseAmount = this.$refs.rightForm.getFieldValue('canUseAmount');
      const num = value ? Number(canUseAmount) + Number(value) : Number(canUseAmount);

      this.$refs.rightForm.setValue({
        afterAdjustAmount: num,
      });
    },
    // 关闭弹窗
    closeModal() {
      this.$emit('onClose');
    },

    // 提交
    submit() {
      const params = {
        id: this.formConfig.row.id,
        changeAmount: this.$refs.leftForm.getFieldValue('changeAmount'),
        businessRemarks: this.$refs.leftForm.getFieldValue('businessRemarks'),
        attachmentList: this.$refs.leftForm.getFieldValue('attachmentList'),
        adjustInFeeBudgetId: this.rightSelect.id,
        feeBudgetDetailType: 'adjust_in',
      };

      if (!params.adjustInFeeBudgetId) {
        this.$message.error('请选择调入方');
      } else if (!params.changeAmount || params.changeAmount === 0) {
        this.$message.error('请填写调整金额');
      } else {
        const url = '/tpm/tpmFeeBudgetController/adjust';
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
  render() {
    const submit = this.submit.bind(this);
    const closeModal = this.closeModal.bind(this);
    const handleFieldLeftChange = this.handleFieldLeftChange.bind(this);
    const handleInitRightChange = this.handleInitRightChange.bind(this);

    return (
      <div>
        <el-row>
          <el-col span={12}>
            <h2 style={{ fontSize: '16px' }}>调出方</h2>
            <LeftForm
              ref={'leftForm'}
              functionCode="tmp_budget_fee_form_edit_adjust"
              modalType="left"
              rowId={this.formConfig.row.id}
              onFieldChange={handleFieldLeftChange}
            ></LeftForm>
          </el-col>
          <el-col span={12}>
            <h2 style={{ fontSize: '16px' }}>调入方</h2>
            {this.rightSelect && this.rightSelect.id ? (
              <RightForm
                ref={'rightForm'}
                functionCode="tmp_budget_fee_form_edit_adjust"
                modalType="right"
                rowId={this.rightSelect.id}
                feeBudgetCode={this.rightSelect.feeBudgetCode}
                onFieldChange={handleInitRightChange}
              ></RightForm>
            ) : (
              <SelectBudgetFee
                v-model={this.rightSelect}
                code={this.formConfig.row.feeBudgetCode}
                onChange={handleInitRightChange}
              ></SelectBudgetFee>
            )}
          </el-col>
        </el-row>
        <div class="dialog-footer">
          <el-button type="primary" onClick={submit} size="small" icon="el-icon-check">
            提交
          </el-button>
          <el-button type="danger" onClick={closeModal} size="small" icon="el-icon-close">
            关闭
          </el-button>
        </div>
      </div>
    );
  },
};
